import './Navigation.css';

export default function JustNameNavBar() {
    return <>
        <nav className="navbar navbar-expand-sm bg-light navbar-light">
            <div className="container">
                <span className="name" href="#">PRASOON PATIDAR</span>
            </div>
        </nav>
    </>
}